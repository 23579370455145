/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import p1 from 'assests/Photos/Celebration/Onam/1.jpg'
import p2 from 'assests/Photos/Celebration/Onam/2.jpg'



import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        ONAM AND TEACHERS’ DAY PROGRAMME
        </Typography>
        <br/>
        <Typography  fontSize = "x-small">
        Date: 5 September 2022<br/>
Classes: Pre-Primary to Class 12
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School Yeshwantpur celebrated Onam and Teachers’ Day on 5 September 2022. A beautiful
and captivating programme was organised by the students to celebrate Onam and Teachers’day.<br/>
The first session of the programme began with the celebration of Onam. The students initiated the celebration
with an invocation song followed by a traditional Onam dance and a skit depicting the banishment of the
Asura King Bali to the netherworld by Vamana an avatar of Lord Vishnu. The students performed wonderfully
bringing out the essence of Onam and thus, creating a festive atmosphere at school.<br/>
The second session of the programme was dedicated for the celebration of Teachers’ Day. The students
expressed their love and gratitude to their teachers through scintillating dance, songs and a heartwarming skit
where the students paid tribute to the teachers and mentors of famous personalities by enacting the
important role they played in shaping their lives. Beautiful pencil stands made by the students were gifted to
each teacher as a token of love and gratitude. The principals concluded the session with a speech eulogizing
the noble profession of teachers.<br/>
All the teachers deeply appreciated the students’ efforts and thoroughly enjoyed the programme.
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
        <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
         “Keeping the spirit Of Onam alive at School”
        </Typography>
        <br/>
        <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
       <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
         “Teachers’ who love teaching make children to love learning”
        </Typography>

        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
